import React, { useState, useEffect } from 'react';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import chooseIMG from "./images/chooseIMG.png";
import chooseIMG2 from "./images/chooseIMG2.png";
import chooseIMG3 from "./images/chooseIMG3.png";
import chooseIMG4 from "./images/chooseIMG4.png";
import chooseIMG5 from "./images/chooseIMG5.png";
import chooseIMG6 from "./images/chooseIMG6.png";
import ventilation from "./images/ventilator.jpg";
import heart from "./images/heart.png";
import blsoxygen from "./images/oxygenBLS.jpg";
import freezerbox from "./images/freezerBox.jpg";
import airAmbulance from "./images/airambulance.jpg";
import emblam from "./images/embalming.jpg";
import { Link } from "react-router-dom";
import footerLogo from "./images/Ambuplus White.png";
import 'react-toastify/dist/ReactToastify.css';


function Home() {
  const [number, setNumber] = useState("");

  useEffect(() => {
    const counter = (id, start, end, duration) => {
      let obj = document.getElementById(id),
        current = start,
        range = end - start,
        increment = end > start ? 1 : -1,
        step = Math.abs(Math.floor(duration / range)),
        timer = setInterval(() => {
          current += increment;
          obj.textContent = current;
          if (current === end) {
            clearInterval(timer);
          }
        }, step);
      
      // Return the timer so it can be cleared
      return timer;
    };
    
    // Keep track of all timers
    const timers = [];
    timers.push(counter("count1", 0, 100, 7000));
    timers.push(counter("count2", 100, 50000, 1000));
    timers.push(counter("count3", 0, 100, 7000));

    // Cleanup function to clear intervals
    return () => {
      timers.forEach(timer => clearInterval(timer));
    };
  }, []);

  const validateNumber = (num) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(num);
  };

  const bookAmbulance = async () => {
    if (!validateNumber(number)) {
      toast.warning("Please enter a valid 10-digit number");
      return;
    }

    const url = "https://ambuplus-server.onrender.com/alert";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ number: number })
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        setNumber("");
      } else {
        toast.error("Something went wrong");
        setNumber("");
      }
    } catch (error) {
      toast.error("Network error");
      setNumber("");
      
    }
  };
  return (
    <>
    
    <div className="calculatedContainer">
    <ToastContainer
      position="top-center"
      autoClose={1000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
      transition= {Zoom}
      />
    <div className="container">
      <div className="row">
        
        <div className="col-md-6 col_md_6_text d-flex flex-column  justify-content-center ">
          <div data-aos="fade-down" data-aos-duration="2000">
            <h1 className="">Get A Call Back In Just 5 Seconds</h1>
          </div>
          {/* <div data-aos="fade-up" data-aos-duration="2000">
            <p className="">
              <span className="d-none d-md-block">
                {" "}
                Experience the assurance of our state-of-the-art ambulances
                equipped with cutting-edge medical technology.
              </span>{" "}
              We provide swift transportation, ensuring that critical care
              begins the moment we arrive

            </p>
          </div> */}
        </div>

        <div className="col-md-6 ">
          <div className="">
            <div data-aos="fade-up" data-aos-duration="2000">
              <div className="tab-content col-12">
                <div data-aos="fade-in" data-aos-duration="2000">
                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col-lg-10 tabs_content_text  ">
                      <div className="home_banner_form">
                        <h5></h5>
                        <div>
                          <div className="">
                            
                            <input
                              className="form-control mt-3"
                              placeholder="Mobile Number"
                              value={number}
                              onChange={(e)=>setNumber(e.target.value)}
                            />
                          </div>
                          <div>
                            <span>
                              <button
                                style={{ width: "fit-content" }}
                                className="my-3 estimate_button mr-2"
                                onClick={bookAmbulance}
                              >
                                Book Ambulance
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className='why_chooseUs_container shadow'>
     <h1 className='text-center pt-3'style={{color:"red"}}>Why Choose Us</h1>
     <div className='container'>
      <div className='row mt-3 '>
        
         <div className='col-md-4 p-2 text-center'>
         <img src={chooseIMG3} alt=''/>
          <h6 >Predictively parked near you</h6>
          <p className='pt-2'>Our in-house technology and data science helps serving you with greater efficiency.</p>
         </div>
         <div className='col-md-4 p-2 text-center'>
          <img src={chooseIMG4} alt=''/>
           <h6>Talking to the right Hospital</h6>
           <p className='pt-2'>We suggest the most relevant hospitals for your caseThey are pre-informed of your arrival.</p>
        </div>
        <div className='col-md-4 p-2 text-center'>
        <img src={chooseIMG5} alt=''/>
            <h6 className='' >Quickest Doctor Checkup & Advice</h6>
            <p className=' pt-2'>With more ambulances added on the road and a smooth book-on-call experience.</p>
      </div>
      <div className='row mt-3 pt-4'>

      <div className='col-md-4 p-2 text-center'>
      <img src={chooseIMG2} alt=''/>
    <h6 className=''> Subsidised Pricing  </h6>
    <p className='pt-2'>Lowest rates of ambulance and guaranteed discounts of 15% at top govt. and pvt. hospitals.</p>
      </div>
      <div className='col-md-4 p-2 text-center'>
      <img src={chooseIMG} alt=''/>
      <h6 className=''> Regular audits of vehicles and staff</h6>
      <p className='pt-2'>All our vehicles are regularly audited and upgraded with equipments.Staff are trained and undergo surprise drills.</p>
</div>
<div className='col-md-4 p-2 text-center'>
<img src={chooseIMG6} alt=''/>
<h6 className=''>Certified Medical Staff</h6>
           <p className=' pt-2'>Ambulance Service with trained and certified medical staff and paramedics.</p>
</div>
</div>
      </div>
    
      </div>
  </div>
  <div className='Services_Container'>
   <h1 className='text-center pt-5'style={{color:"red"}}>The Services We Offer</h1>
   <div className='container'>
   
    <div className='row mt-3'>
     <div className='col-md-4 col-12 col-sm-6 p-1'>
       <div className='card services_cards'>
        <img src={ventilation} alt='' className='rounded'/>
           <h5 className='pt-2  text-center'>ICU Ventilator services</h5>
           <p>Having dependable and prompt ICU Ventilator ambulance services is essential during medical emergencies when every second counts</p>
           <div className='btn justify-center'>
           <Link to="/ventilator-ambulance">
            <button>ReadMore <i class="ri-arrow-right-line"></i></button>
            </Link>
            </div>
           
       </div>
     </div>
     <div className='col-md-4  col-sm-6 col-12 p-1'>
       <div className='card services_cards'>
        <img src={blsoxygen} alt='' className='rounded' />
        <h5 className='pt-2  text-center'>BLS oxygen Ambulance</h5>
        <p>BLS ambulance outfitted with essential medical equipment and oxygen supply for emergency patient care and transport</p>

        <div className='btn justify-center'>
        <Link to="/BLS-ambulance">
          <button>ReadMore <i class="ri-arrow-right-line"></i></button>
          </Link>
          </div>

       </div>
     </div>
     <div className='col-md-4 col-12 col-sm-6 p-1'>
       <div className='card services_cards'>
       <img src={airAmbulance} alt=''  className='rounded'/>
       <h5 className='pt-2 text-center '>AIR Ambulance Services</h5>
       <p>Air ambulance service providing essential oxygen support for critical care patients during aerial medical transport</p>
       <div className='btn justify-center'>
       <Link to="/Air-ambulance">
        <button>ReadMore <i class="ri-arrow-right-line"></i></button>
        </Link>
        </div>
       </div>
     </div>
    </div>
    <div className='row mt-3 pt-4'>
     <div className=' col-md-4 p-1'>
       <div className='card services_cards '>
       <img src={freezerbox} alt=''  className='rounded'/>
<h5 className='pt-2  text-center'>Freezer Box services</h5>
<p>Facilitating freezer box services for the dignified and respectful transportation of deceased individuals with care.</p>
<div className='btn justify-center'>
<Link to="/freezer-box">
  <button>ReadMore</button> <i class="ri-arrow-right-line"></i>
  </Link>
  </div>
       </div>
     </div>
     <div className='col-md-4 p-1'>
       <div className='card services_cards'>
       <img src={emblam} alt=''  className='rounded'/>
<h5 className='pt-2  text-center'>Embalming services</h5>
<p>The procedure of embalming a deceased person is careful and courteous, guaranteeing that the body will be preserved in a respectable manner until the burial or viewing</p>
<div className='btn justify-center'>
<Link to="/Embalming">
  <button>ReadMore</button> <i class="ri-arrow-right-line"></i>
  </Link>
  </div>
       </div>
     </div>
    
    </div>

   </div>

  </div>
  <section className="Count_Container mt-4 pt-5">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12">
           
          </div>
          <div className="col-md-4">
          <i class="fa-regular fa-hospital fa-xl"></i>
             <h6>Hospitals Serving</h6>
            <span id="count1" className="display-4">0</span><span style={{fontSize:'50px'}}>+</span>
          </div>
          <div className="col-md-4">
          <i class="fa-solid fa-bed-pulse fa-xl"></i>
            <h6>Lives saved</h6>
            <span id="count2" className="display-4">0</span><span style={{fontSize:'50px'}}>+</span>
          </div>
          <div className="col-md-4">
          <i class="fa-solid fa-city fa-xl"></i>
            <h6>Cities</h6>
            <span id="count3" className="display-4">0</span><span style={{fontSize:'50px'}}>+</span>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container mt-5">
        <div className="text-center mb-5">
          <h2 className="technologiesHeading ">Get In Touch ?</h2>
        </div>

        <div className="row">
          <div className="col-md-10">
            <div className="container">
              <div className="row">
                <div className="col-md-7 mb-2">
                  <div data-aos="fade-down" data-aos-duration="2000">
                    <div className="d-flex justify-content-center my-2">
                      <img
                        src="https://img.freepik.com/free-photo/smiling-young-female-doctor-wearing-medical-robe-stethoscope-sitting-desk-with-medical-tools-using-looking-laptop-talking-phone-isolated-white-wall_141793-59558.jpg?t=st=1715873192~exp=1715876792~hmac=9ad6af51b30db200a67938a4aa896a0e44c3092d8bc8ce584e9008eb04daea7a&w=740"
                        alt="shhbl"
                        className="handimg"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-5 my-4">
                  <div data-aos="fade-up" data-aos-duration="2000">
                    <p className="contactText">
                      When seconds matter, choose AmbuPlus ambulance. Call us for
                      immediate assistance. Your health is our priority, and
                      we're here for you 24/7
                    </p>
                  
                    <p className="conPhn mt-4">
                        <button
                          onClick={() =>
                            (window.location.href = "tel:+917075906789")
                          }
                          className="red_button"
                        >
                          <i className="fa-solid fa-phone-volume"></i> Call now
                        </button>
                        <a
                          href="https://wa.me/917075906789"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="ml-2 green_button ">
                            <i class="fa-brands fa-whatsapp "></i> Chat with us
                          </button>
                        </a>
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="container-fluid px-2 px-md-4 py-5 mx-auto">
    <div className="row d-flex justify-content-center">
        <div className="col-md-11 col-lg-11 col-xl-11">
            <div className="card card-main border-0 text-center">
                <h5 className="font-weight-bold mb-4">What our clients are saying<br/>about us.</h5>
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1" className="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner">
                        <div className="carousel-item">
                            <div className="card border-0 card-0">
                                <div className="card profile py-3 px-4">
                                    <div className="text-center">
                                        <img src="https://i.imgur.com/gazoShk.jpg" className="img-fluid profile-pic" alt="Profile"/>
                                    </div>
                                    <h6 className="mb-0 mt-2">Charitha</h6>
                                   
                                </div>
                                <h6 className="rating_names">Charitha</h6>
                                <img className="img-fluid open-quotes" src="https://i.imgur.com/Hp91vdT.png" alt="Open quotes" width="20" height="20"/>
                                <p className="content mb-0">Great ambulance services with polite staff prioritizing patient care. Prompt responses and reasonable prices. Highly impressed with their attentive handling of patients </p>
                                <div className='ratings'>
                                <i className="fa-solid fa-star rating_stars"></i>
                                <i className="fa-solid fa-star rating_stars"></i>
                                <i className="fa-solid fa-star rating_stars"></i>
                                <i className="fa-solid fa-star rating_stars"></i>
                                <i className="fa-solid fa-star-half-stroke rating_stars"></i>
                                </div>
                                <img className="img-fluid close-quotes ml-auto" src="https://i.imgur.com/iPcHyJK.png" alt="Close quotes" width="20" height="20"/>
                            </div>
                        </div>
                        <div className="carousel-item active">
                            <div className="card border-0 card-0">
                                <div className="card profile py-3 px-4">
                                    <div className="text-center">
                                        <img src="https://i.imgur.com/oW8Wpwi.jpg" className="img-fluid profile-pic" alt="Profile"/>
                                    </div>
                                    <h6 className="mb-0 mt-2">Geetha</h6>
                                    
                                </div>
                                <h6 className="rating_names">Geetha</h6>
                                <img className="img-fluid open-quotes" src="https://i.imgur.com/Hp91vdT.png" alt="Open quotes" width="20" height="20"/>
                                <p className="content mb-0">They offered us the best service. Very supportive people I have ever seen. Helped us to shift the patient to hospital and back home with all care. Fully equipped ambulance it is. We could have professional technician as needed.</p>
                                <div className='ratings'>
                                <i className="fa-solid fa-star rating_stars"></i>
                                <i className="fa-solid fa-star rating_stars"></i>
                                <i className="fa-solid fa-star rating_stars"></i>
                                <i className="fa-solid fa-star rating_stars"></i>
                                <i className="fa-solid fa-star-half-stroke rating_stars"></i>
                                </div>
                                <img className="img-fluid close-quotes ml-auto" src="https://i.imgur.com/iPcHyJK.png" alt="Close quotes" width="20" height="20"/>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="card border-0 card-0">
                                <div className="card profile py-3 px-4">
                                    <div className="text-center">
                                        <img src="https://i.imgur.com/ndQx2Rg.jpg" className="img-fluid profile-pic" alt="Profile"/>
                                    </div>
                                    <h6 className="mb-0 mt-2">Dinesh Kumar</h6>
                                    
                                </div>
                                <h6 className="rating_names">Dinesh Kumar</h6>
                                <img className="img-fluid open-quotes" src="https://i.imgur.com/Hp91vdT.png" alt="Open quotes" width="20" height="20"/>
                                <p className="content mb-0">Efficient service with compassionate paramedics. Highly recommended for urgent medical assistance in Hyderabad..AmbiPlus Ambulances</p>
                                <div className='ratings'>
                                <i className="fa-solid fa-star rating_stars"></i>
                                <i className="fa-solid fa-star rating_stars"></i>
                                <i className="fa-solid fa-star rating_stars"></i>
                                <i className="fa-solid fa-star rating_stars"></i>
                                <i className="fa-solid fa-star rating_stars"></i>
                                </div>
                                <img className="img-fluid close-quotes ml-auto" src="https://i.imgur.com/iPcHyJK.png" alt="Close quotes" width="20" height="20"/>
                              {/* <div className='ratings'>
                                <i className="fa-solid fa-star rating_stars"></i>
                                <i className="fa-solid fa-star rating_stars"></i>
                                <i className="fa-solid fa-star rating_stars"></i>
                                <i className="fa-solid fa-star rating_stars"></i>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="floating-icons">
        <a href="tel:+917075806789" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+917075806789")}
            className="red_fixed_button"
          >
            <i className="fa-solid fa-phone-volume"></i> +91 7075806789
          </button>
        </a>
        <a href="https://wa.me/+917075806789" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="fa-brands fa-whatsapp "></i> Whatsapp
          </button>
        </a>
      </div>


    <div className="footerSection ">
        <div className="container">
          <div className="row  firstFooterContainer ">
            <div className="firstCol  col-12">
              <div>
                <img
                  src={footerLogo}
                  className="pabTopLogo"
                  id="bottomPabLogo"
                  alt=""
                />
                <p className="footerText">
                  Our commitment to expert precision shines through, guiding you
                  from moments of emergency to a realm of dedicated care. Our
                  ambulance services embody the essence of Rapidness, ensuring
                  that with just a call, essential care is at your fingertips.
                  Recognizing that every second holds profound significance, we
                  unleash the power of urgent, professional care, understanding
                  that your well-being hinges on rapid intervention.
                </p>
              </div>
            </div>

            <div className="thirdCol  col-12 ">
              <h4 className="text-center">Services </h4>

              <div className="d-flex flex-wrap justify-content-around">
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/BLS-ambulance">
                    <span>Basic Life Support</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/ventilator-ambulance">
                    <span>Ventilator Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/Air-ambulance">
                    <span>Air Ambulance Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/freezer-box">
                    <span> Freezer Box Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/Embalming">
                    <span>Embalming</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div
            className="thick-horizontal-line mx-2 "
            style={{ height: "1px", backgroundColor: "white" }}
          ></div>

          <div className="container secondFooterContainer row ">
            <div className="text-white col-md-6  col-12 align-self-center mt-4">
              <i className="fa-solid fa-copyright"></i> Copyright AMBUPLUS
              Ambulance. All rights reserved.
            </div>

            <div className=" col-md-2  d-flex flex-row col-12 mt-4 ">
              <i className="fa-brands fa-linkedin mx-2 logos" id="linkedIn"></i>
              <i
                className="fa-brands fa-instagram mx-2 logos"
                id="instagram"
              ></i>
              <i className="fa-brands fa-facebook mx-2 logos" id="facebook"></i>
              <i className="fa-brands fa-twitter mx-2 logos" id="twitter"></i>
            </div>
          </div>
        </div>
      </div>
  
  </>
  )
}

export default Home