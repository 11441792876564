import React from "react";
import { Link } from "react-router-dom";

import footerLogo from "./images/Ambuplus White.png";

const About = () => {
  return (
    <div style={{ marginTop: "80px" }}>
      <div className="floating-icons">
        <a href="tel:+917075806789" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+917075806789")}
            className="red_fixed_button"
          >
            <i className="fa-solid fa-phone-volume"></i> +91 7075806789
          </button>
        </a>
        <a href="https://wa.me/+917075806789" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="fa-brands fa-whatsapp "></i> Whatsapp
          </button>
        </a>
      </div>
      <div className="about_bg">
        <h1>ABOUT <span style={{color:'red'}}>AMBU</span>PLUS</h1>
      </div>
      <div className="container About_page">
        <p>
         AMBUPLUS Services is among the top choices for affordable emergency
          ambulance services in Hyderabad, ensuring swift and safe
          transportation of patients.
        </p>

        <p>
          Listed below are some of the benefits you can avail with AMBUPLUS
          Ambulance service in Hyderabad:
        </p>

        <p>
          <b>24/7 Accessibility:</b> Recognizing the importance of timely
          medical care, we offer round-the-clock ambulance services in Hyderabad
          and other cities of Telangana. We dispatch ambulances promptly as per
          the patient's requirements upon receiving your booking request. Our
          air ambulance service in Hyderabad operates 24/7.
        </p>

        <p>
          <b>Prompt Response: </b>When every second counts, rely on Ambuplus
         Services prompt response. Our competent medical staff, experienced
          drivers, trained nurses, and well-equipped ambulances are always
          prepared to transfer patients to hospitals via road, air, or train.
          For quick ambulance booking in India, download the AmbuPlus
          App.
        </p>

        <p>
          <b> Expert Medical Care:</b> We boast skilled and trained medical
          professionals to deliver expert care during medical transportation.
          Our doctors are dedicated to providing expert medical care and
          emotional support that patients and their families require during
          challenging times.
        </p>

        <p>
          <b> Cost-Effective:</b> We offer a range of ambulance services in
          Hyderabad at competitive prices without compromising on quality. There
          are no additional or hidden charges. You can trust us to provide
          fair-cost ambulances in Hyderabad, Telangana.
        </p>

        <p>
          We provide various types of ambulances such as BLS ambulance, ALS
          ambulance, Dead Body Ambulance Services, and Air Ambulance Services in
          Hyderabad to meet all medical transportation needs.AMBUPLUS 
          offers non-emergency medical transport, emergency medical services
          (EMS), inter-hospital transfers, and neonatal and pediatric transport
          at affordable rates. Be prepared for emergencies with AMBUPLUS!
          Book an ambulance service online via the AmbUPlus app or call
          our Hyderabad ambulance service number at 18008-908-208.
        </p>
      </div>

      <div className="footerSection ">
        <div className="container">
          <div className="row  firstFooterContainer ">
            <div className="firstCol  col-12">
              <div>
                <img
                  src={footerLogo}
                  className="pabTopLogo"
                  id="bottomPabLogo"
                  alt=""
                />
                <p className="footerText">
                  Our commitment to expert precision shines through, guiding you
                  from moments of emergency to a realm of dedicated care. Our
                  ambulance services embody the essence of Rapidness, ensuring
                  that with just a call, essential care is at your fingertips.
                  Recognizing that every second holds profound significance, we
                  unleash the power of urgent, professional care, understanding
                  that your well-being hinges on rapid intervention.
                </p>
              </div>
            </div>

            <div className="thirdCol  col-12 ">
              <h4 className="text-center">Services </h4>

              <div className="d-flex flex-wrap justify-content-around">
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/BLS-ambulance">
                    <span>Basic Life Support</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/ventilator-ambulance">
                    <span>Ventilator Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/Air-ambulance">
                    <span>Air Ambulance Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/freezer-box">
                    <span> Freezer Box Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/Embalming">
                    <span>Embalming</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div
            className="thick-horizontal-line mx-2 "
            style={{ height: "1px", backgroundColor: "white" }}
          ></div>

          <div className="container secondFooterContainer row ">
            <div className="text-white col-md-6  col-12 align-self-center mt-4">
              <i className="fa-solid fa-copyright"></i> Copyright AMBUPLUS
              Ambulance. All rights reserved.
            </div>

            <div className=" col-md-2  d-flex flex-row col-12 mt-4 ">
              <i className="fa-brands fa-linkedin mx-2 logos" id="linkedIn"></i>
              <i
                className="fa-brands fa-instagram mx-2 logos"
                id="instagram"
              ></i>
              <i className="fa-brands fa-facebook mx-2 logos" id="facebook"></i>
              <i className="fa-brands fa-twitter mx-2 logos" id="twitter"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
