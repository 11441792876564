import React from "react";
import { Link } from "react-router-dom";
import footerLogo from "./images/Ambuplus White.png";
import contactimg from "./images/ambu_conatct_us_img.png";
import { BiPhoneCall } from "react-icons/bi";

const Contact = () => {
  return (
    <div style={{ marginTop: "100px" }}>
      <div className="container">
        <div className="row contact_page">
          <div className="col-md-6">
            <img src={contactimg} className="img-fluid" />
          </div>
          <div className="col-md-6 m-auto contact_us mt-2">
            <h2>Never Hesitate</h2>
            <h1>To Get in touch with us</h1>

            <p className="mb-4">
              Contact AMBUPLUS Ambulances for dependable care you can rely on. Our
              team is available 24/7 and prepared to deliver top-notch emergency
              response services in India. Don't hesitate - get the assistance
              you require now by reaching out to AMBUPLUS Ambulances.
            </p>

            <a href="tel:+917075806789" className="call_emergency">
              <div className="icons">
                <BiPhoneCall />
              </div>
              <div className="contact">
                <h3>SAVE IT FOR EMERGRNCY</h3>
                <p>Call Us: +917075806789</p>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="floating-icons">
        <a href="tel:+917075806789" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+917075806789")}
            className="red_fixed_button"
          >
            <i className="fa-solid fa-phone-volume"></i> +91 7075806789
          </button>
        </a>
        <a href="https://wa.me/+917075806789" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="fa-brands fa-whatsapp "></i> Whatsapp
          </button>
        </a>
      </div>

      <div className="footerSection ">
        <div className="container">
          <div className="row  firstFooterContainer ">
            <div className="firstCol  col-12">
              <div>
                <img
                  src={footerLogo}
                  className="pabTopLogo"
                  id="bottomPabLogo"
                  alt=""
                />
                <p className="footerText">
                  Our commitment to expert precision shines through, guiding you
                  from moments of emergency to a realm of dedicated care. Our
                  ambulance services embody the essence of Rapidness, ensuring
                  that with just a call, essential care is at your fingertips.
                  Recognizing that every second holds profound significance, we
                  unleash the power of urgent, professional care, understanding
                  that your well-being hinges on rapid intervention.
                </p>
              </div>
            </div>

            <div className="thirdCol  col-12 ">
              <h4 className="text-center">Services </h4>

              <div className="d-flex flex-wrap justify-content-around">
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/BLS-ambulance">
                    <span>Basic Life Support</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/ventilator-ambulance">
                    <span>Ventilator Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/Air-ambulance">
                    <span>Air Ambulance Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/freezer-box">
                    <span> Freezer Box Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/Embalming">
                    <span>Embalming</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div
            className="thick-horizontal-line mx-2 "
            style={{ height: "1px", backgroundColor: "white" }}
          ></div>

          <div className="container secondFooterContainer row ">
            <div className="text-white col-md-6  col-12 align-self-center mt-4">
              <i className="fa-solid fa-copyright"></i> Copyright AMBUPLUS
              Ambulance. All rights reserved.
            </div>

            <div className=" col-md-2  d-flex flex-row col-12 mt-4 ">
              <i className="fa-brands fa-linkedin mx-2 logos" id="linkedIn"></i>
              <i
                className="fa-brands fa-instagram mx-2 logos"
                id="instagram"
              ></i>
              <i className="fa-brands fa-facebook mx-2 logos" id="facebook"></i>
              <i className="fa-brands fa-twitter mx-2 logos" id="twitter"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
